import {
    Table, Space, Spin, Row, Col, Input, Button, DatePicker, AutoComplete, Select
} from 'antd';
import moment from "moment";
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { GetProgramProductCode } from "../../api/ProgramTable";
import { GetProduct } from "../../api/GetProduct";
import DeviceModal from "./DeviceModal";
import ProductWorkerModal from './ProductWorkerModal';
const { RangePicker } = DatePicker;
const GroupbyOPCode = () => {
    const { Option } = Select;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const [endDate, setEndDate] = useState([moment().startOf('day').format(dateFormat), moment().format(dateFormat)]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: '程式名稱',
            dataIndex: 'ProgramNo',
            key: 'ProgramNo',
        },
        {
            title: '物料號碼',
            dataIndex: 'ProductNumber',
            key: 'ProductNumber',
        },
        {
            title: '物料名稱',
            dataIndex: 'ProductName',
            key: 'ProductName',
        },
        {
            title: 'OP Code',
            dataIndex: 'OPCode',
            key: 'OPCode',
        },
        {
            title: '平均CT',
            dataIndex: 'CtAvg',
            key: 'CtAvg',
            sorter: (a, b) => a.CtAvg - b.CtAvg,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最大CT',
            dataIndex: 'CtMax',
            key: 'CtMax',
            sorter: (a, b) => a.CtMax - b.CtMax,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最小CT',
            dataIndex: 'CtMin',
            key: 'CtMin',
            sorter: (a, b) => a.CtMin - b.CtMin,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '加工次數',
            dataIndex: 'TotalCount',
            key: 'TotalCount',
            sorter: (a, b) => a.CtMin - b.CtMin,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '更新時間',
            dataIndex: 'UpdateTime',
            key: 'UpdateTime',
            render: (record) => (
                <>
                    {moment(record).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '查看',
            key: 'edit',
            render: (record) => (
                <Space >
                    <DeviceModal record={record} date={endDate}/>
                    <ProductWorkerModal  record={record} date={endDate}/>
                </Space>)
        },
    ];

    const [programData, setProgramData] = useState([]);
    const [programNo, setProgramNo] = useState("");
    const [OPCode, setOPCode] = useState("");
    const [productNumber, setProductNumber] = useState("");
    const [productOptions, setProductOptions] = useState([]);

    async function onChangeProgramNo(e) {
        setProgramNo(e.target.value)
    }

    async function onChangeOPCode(e) {
        setOPCode(e.target.value)
    }
    async function onChangeProductNumber(e) {
        setProductNumber(e)
    }
    async function onChangeDate(value, dateString) {
        setEndDate(dateString);
    }
    async function searchProgram() {
        setLoading(true);
        setProgramData("");
        await GetProgramProductCode(endDate, programNo, OPCode, productNumber,"").then(data => {
            setProgramData(data);
        })
            .catch(err => console.log(err))
        setLoading(false);
    }
    async function onSearchProduct(event) {
        await GetProduct(event).then(data => {
            setProductOptions(data.data);
        })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        searchProgram();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col span={3} >
                            <h1>更新時間 : </h1>
                        </Col>
                        <Col span={5} >
                            <RangePicker showTime
                                onChange={onChangeDate}
                                style={{ position: 'relative', textAlign: 'center', right: '5vh' }}
                                defaultValue={[moment().startOf('day'), moment()]}
                                format={dateFormat}
                            />
                        </Col>
                        <Col span={2} >
                            <h1>程式名稱 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeProgramNo} allowClear placeholder="程式名稱" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={2} >
                            <h1>OP Code : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeOPCode} allowClear placeholder="OP Code" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={[16, 16]} >
                        <Col span={2} >
                            <h1>物料號碼 : </h1>
                        </Col>
                        <Col span={8} >
                            <AutoComplete
                                onChange={onChangeProductNumber}
                                allowClear
                                style={{ position: 'relative', left: '28px', width: '50vh' }}
                                onSearch={onSearchProduct}
                                placeholder="input here"
                            >
                                {
                                    productOptions.map((products) => {
                                        return (
                                            <Option key={products.uuid} value={products.number}>{products.number}, {products.name}</Option>
                                        )
                                    })
                                }
                            </AutoComplete>
                        </Col>
                        <Col span={3} >
                            <Button size="middle" type="primary" onClick={searchProgram}>搜尋</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <Spin spinning={loading}>
                {/* <Table columns={columns} dataSource={programData} style={{ position: 'relative', top: '5%' }} pagination={{ pageSize: 8 }}>
                </Table> */}
                <Table
                    scroll={{ y: 500 }}
                    columns={columns}
                    dataSource={programData}
                    bordered
                />
            </Spin>
        </>
    )
}
export default GroupbyOPCode;
