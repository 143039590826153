import { Button, Modal, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { PatchOpResource } from '../../api/OpResouce';

function ProgramModal({ record, refreshProgram }) {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState();
    const [form] = Form.useForm();
    async function editProgram() {
        setVisible(true);
        form.setFieldsValue(record);
        setTitle("設定程式")
    }
    function handleCancel() {
        setVisible(false);
        form.resetFields();
    }

    async function setChangeData() {
        const tempProgramData = form.getFieldsValue(true);
        await PatchOpResource(tempProgramData);
        message.success('儲存設定成功!');
        setVisible(false);
        form.resetFields();
        //refresh data
        setTimeout(() => {
            refreshProgram();
        }, 1500);
    }

    function createProgram() {
        setVisible(true);
    }
    // function onChangeCheckBox(e) {
    //     console.log("value", e.target.checked)
    //     // setCheckBoxLabel(e.target.checked.toString());
    //     // setCheckBox(e.target.checked);
    // }
    return (
        <div>
            {
                record ?
                    <Button type="primary" onClick={editProgram}>設定</Button>
                    :
                    <Button type="primary" onClick={createProgram}>新增</Button>
            }
            <Modal
                width={1000}
                title={title}
                visible={visible}
                onCancel={handleCancel}
                footer={
                    [
                        <Button key="cancel" onClick={handleCancel} style={{ position: 'relative', right: '40%' }}>
                            取消
                        </Button>,
                    ]}
            >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    onFinish={setChangeData}
                    form={form}
                >
                    <Form.Item
                        name="source_path"
                        label="Local路徑"
                        rules={[{ required: true, message: '請輸入Local路徑!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        name="source_file_name"
                        label="Local程式號碼"
                        rules={[{ required: true, message: '請輸入Local程式號碼!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="機台程式號碼"
                        name="destination_file_name"
                        rules={[{ required: true, message: '請輸入機台程式號碼!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="機台路徑"
                        name="destination_path"
                        rules={[{ required: true, message: '請輸入機台路徑!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" style={{ position: 'relative', top: '67px', left: '40%' }}>存檔</Button>
                </Form>
            </Modal>
        </div>

    )
}
export default ProgramModal;
