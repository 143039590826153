import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import _ from "lodash";
import { Bar } from 'react-chartjs-2';

function GroupDeviceChart({ chartData, chartLabel, sort, loading }) {
    const [labelName, setLabelName] = useState([]);
    const [CtAvg, setCtAvg] = useState([]);

    function chartLoad(data) {
        if (sort) {
            if (chartLabel === 'all') {
                data = _.orderBy(data, ['CtAvg', 'DeviceName'], ['desc', 'asc'])
                setLabelName(data.map(val => val.DeviceName + "/" + val.ProgramNo))
            }
            if (chartLabel === 'deviceName') {
                data = _.orderBy(data, ['CtAvg', 'DeviceName'], ['desc', 'asc'])
                setLabelName(data.map(val => val.DeviceName))
            }
            if (chartLabel === 'programNo') {
                data = _.orderBy(data, ['CtAvg', 'ProgramNo'], ['desc', 'asc'])
                setLabelName(data.map(val => val.ProgramNo))
            }

        } else {
            if (chartLabel === 'all') {
                data = _.orderBy(data, ['CtAvg', 'DeviceName'], ['asc', 'asc'])
                setLabelName(data.map(val => val.DeviceName + "/" + val.ProgramNo))
            }
            if (chartLabel === 'deviceName') {
                data = _.orderBy(data, ['CtAvg', 'DeviceName'], ['asc', 'asc'])
                setLabelName(data.map(val => val.DeviceName))
            }
            if (chartLabel === 'programNo') {
                data = _.orderBy(data, ['CtAvg', 'ProgramNo'], ['asc', 'asc'])
                setLabelName(data.map(val => val.ProgramNo))
            }

        }
        setCtAvg(data.map(val => val.CtAvg))
    }
    useEffect(() => {
        chartLoad(chartData);
        // eslint-disable-next-line
    }, [chartData, sort])
    const data = {
        labels: labelName,
        datasets: [{
            label: '平均CT',
            data: CtAvg,
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            borderWidth: 1
        }]
    };
    return (
        <div>
            <Spin spinning={loading}>
                <Bar
                    height="80%"
                    data={data}
                    options={{
                        title: {
                            display: true,
                            text: 'Average Rainfall per month',
                            fontSize: 20
                        },
                        legend: {
                            display: true,
                            position: 'right'
                        }
                    }}
                />
            </Spin>
        </div>
    )

};
export default GroupDeviceChart;
