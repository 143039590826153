import axiosInstance from "../api/axios";
export const GetOpResource = () => {
    const promise = axiosInstance.get(`https://dotzerotech-work-order-api.dotzero.app/v1/opResource/`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
export const PatchOpResource = (programData) => {
    const promise = axiosInstance.patch(`https://dotzerotech-work-order-api.dotzero.app/v1/opResource/${programData.uuid}`, programData);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
