import { Button, Modal, Form, Spin, Table } from 'antd';
import React, { useState } from 'react';
import moment from "moment";
import { GetProgramProduct } from '../../api/ProgramTable';

function ProductModal({ record, date }) {
    const [programData, setProgramData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: '物料號碼',
            dataIndex: 'ProductNumber',
            key: 'ProductNumber',
        },
        {
            title: '物料名稱',
            dataIndex: 'ProductName',
            key: 'ProductName',
        },
        {
            title: '加工次數',
            dataIndex: 'TotalCount',
            key: 'TotalCount',
        },
        {
            title: '平均CT',
            dataIndex: 'CtAvg',
            key: 'CtAvg',
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最大CT',
            dataIndex: 'CtMax',
            key: 'CtMax',
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最小CT',
            dataIndex: 'CtMin',
            key: 'CtMin',
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '更新時間',
            dataIndex: 'UpdateTime',
            key: 'UpdateTime',
            render: (record) => (
                <>
                    {moment(record.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
    ];
    async function getProduct() {
        setLoading(true);
        setVisible(true);
        await GetProgramProduct(date, record.DeviceUUID, record.ProgramNo).then(data => {
            setProgramData(data);
        })
            .catch(err => console.log(err))
        setLoading(false);
        form.setFieldsValue(record);
        setTitle("查看物料")
    }
    function handleCancel() {
        setVisible(false);
        form.resetFields();
    }
    return (
        <div>
            <Button type="primary" onClick={getProduct}>查看物料</Button>
            <Modal
                width={1000}
                title={title}
                visible={visible}
                onCancel={handleCancel}
                footer={
                    [
                        <Button key="cancel" onClick={handleCancel}>
                            取消
                        </Button>,
                    ]}
            >
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={programData} style={{ position: 'relative', top: '5%' }} pagination={{ pageSize: 8 }}>
                    </Table>
                </Spin>
            </Modal>
        </div>
    )
}
export default ProductModal;
