import {
    Table, Space, Spin, Row, Col
} from 'antd';
import React, { useState, useEffect } from 'react';
import ProgramModal from './ProgramModal';
import { GetOpResource } from "../../api/OpResouce";
const ProgramSet = () => {
    const [opResource, setOpResource] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: 'Device UUID',
            // dataIndex: 'ProgramNo',
            // key: 'ProgramNo',
        },
        {
            title: '物料 UUID',
            // dataIndex: 'DeviceName',
            // key: 'DeviceName',
        },
        {
            title: 'Operation UUID',
            dataIndex: 'operation_uuid',
            key: 'operation_uuid'
        },
        {
            title: 'Route UUID',
            // dataIndex: 'TotalCount',
            // key: 'TotalCount'
        },
        {
            title: 'Local路徑',
            dataIndex: 'source_path',
            key: 'source_path'
        },
        {
            title: 'Local程式號碼',
            dataIndex: 'source_file_name',
            key: 'source_file_name'
        },
        {
            title: '機台程式號碼',
            dataIndex: 'destination_file_name',
            key: 'destination_file_name'
        },
        {
            title: '機台路徑',
            dataIndex: 'destination_path',
            key: 'destination_path'
        },
        {
            title: '編輯',
            key: 'edit',
            render: (record) => (
                <Space size="middle">
                    <ProgramModal record={record} refreshProgram={searchProgram}></ProgramModal>
                    {/* <Popconfirm title="是否要刪除此筆資料?" okText="Yes" cancelText="No" onConfirm={() => handleDelete(record)}>
                        <Button danger>刪除</Button>
                    </Popconfirm> */}
                </Space>)
        },
    ];

    async function searchProgram() {
        setLoading(true);
        setOpResource("");
        await GetOpResource().then(data => {
            setOpResource(data.data);
        })
            .catch(err => console.log(err))
        setLoading(false);
    }

    useEffect(() => {
        searchProgram();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                    </Row>
                    <Row gutter={[16, 16]} >
                        {/* <Col span={2} >
                            <h1>程式名稱 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeCustomerName} placeholder="程式名稱" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={2} >
                            <h1>機台 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeCustomerNumber} placeholder="機台" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={3} >
                            <Button size="middle" type="primary" onClick={searchProgram}>搜尋</Button>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
            <Spin spinning={loading}>
                <Table columns={columns} dataSource={opResource} style={{ position: 'relative', top: '5%' }} pagination={{ pageSize: 8 }}>
                </Table>
            </Spin>
        </>
    )
}
export default ProgramSet;
