import axiosInstance from "../api/axios";
// import { axiosInstance, BASE_URL } from './apiService';
//group by device_uuid and program_no
//manyoutput_history join work_order_op_history
export const GetProgramDevice = (endEndDate, programNo, deviceName, limit) => {
    const promise = axiosInstance.get(`/program/device?from=${endEndDate[0]}&to=${endEndDate[1]}&programNo=${programNo}&deviceName=${deviceName}&limit=${limit}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};

export const GetProgramProductCode = (endEndDate, programNo, OPCode, productNumber, limit) => {
    const promise = axiosInstance.get(`/program/product?from=${endEndDate[0]}&to=${endEndDate[1]}&programNo=${programNo}&opCode=${OPCode}&productNumber=${productNumber}&limit=${limit}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
//product modal
export const GetProgramProduct = (endEndDate, deviceUUID, programNo) => {
    console.log(endEndDate)
    const promise = axiosInstance.get(`/program/productDetail?from=${endEndDate[0]}&to=${endEndDate[1]}&deviceUUID=${deviceUUID}&programNo=${programNo}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
//worker modal
export const GetProgramWorker = (endEndDate, deviceUUID, programNo) => {
    const promise = axiosInstance.get(`/program/workerDetail?from=${endEndDate[0]}&to=${endEndDate[1]}&deviceUUID=${deviceUUID}&programNo=${programNo}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
//device modal
export const GetProgramDeviceDetail = (endEndDate, record) => {
    const promise = axiosInstance.get(`/program/deviceDetail?from=${endEndDate[0]}&to=${endEndDate[1]}&deviceUUID=${record.DeviceUUID}&programNo=${record.ProgramNo}&productNumber=${record.ProductNumber}&opCode=${record.OPCode}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
//product worker modal
export const GetProductWorker = (endEndDate, record) => {
    const promise = axiosInstance.get(`/program/productWorker?from=${endEndDate[0]}&to=${endEndDate[1]}&deviceUUID=${record.DeviceUUID
        }&programNo=${record.ProgramNo}&productNumber=${record.ProductNumber}&opCode=${record.OPCode}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
