import './App.css';
import Layout from "./layout/layout";
import { BrowserRouter as Router } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import queryString from 'query-string';
import {
  initAxiosInstance,
  getAppPermission,
} from './api/apiService';
function App() {
  const token = Cookies.get('token');
  const user = Cookies.get('user');
  if (process.env.REACT_APP_ENV === 'dev') {
    const t = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImJlYmYxMDBlYWRkYTMzMmVjOGZlYTU3ZjliNWJjM2E2YWIyOWY1NTUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRFogQWRtaW4iLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYWxlcnQtaGVpZ2h0LTI1NzMwNCIsImF1ZCI6ImFsZXJ0LWhlaWdodC0yNTczMDQiLCJhdXRoX3RpbWUiOjE2NTI2ODA0NzUsInVzZXJfaWQiOiIwVUt5S1MzVmw4ZTExc3VhWVFqdmYydWp6SXoxIiwic3ViIjoiMFVLeUtTM1ZsOGUxMXN1YVlRanZmMnVqekl6MSIsImlhdCI6MTY1MjY4MDQ3NSwiZXhwIjoxNjUyNjg0MDc1LCJlbWFpbCI6ImRldi5pb0Bkb3R6ZXJvLnRlY2giLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiZGV2LmlvQGRvdHplcm8udGVjaCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIiwidGVuYW50IjoiZ3JvdXAxLXQweXEwIn19.iDBm-mfdLVQLw-UZKChFUWfkFe0AJk7udBAj-kUzbgy_2QJRYgluZc3oZaLAHkYZm5xV7A-dQI94KSP5jBywQDtnZXxTHf4K3M65poT2Wrvj1nSYH0qRf382b7-jMZcHjpBech3abdmSW1S8E9HO0QfTTP0dtsTwGhuOV9f9w1tfKvPVMycUuwpiLzy7xZhrJvN8xBY2pA15nuA6C6A1h_fA0luN7Vnc3-n-Jyfm6b2yiYW45hf4gpenjb5iVz8FKUVhzIrpLzseq0TstiQHjDt3ymWwbHA02ZwfK6kp8J_WxYFicwlg8OiV0MN6W_dexiezyehUvwxoYtbt1Yth1g';
    Cookies.set('token', `${t}`);
    axios.defaults.headers.common['Authorization'] = `Bearer ${t}`;
    sessionStorage.setItem('tenant_id', 'group1-t0yq0');
    initAxiosInstance();
  } else {
    if (token !== undefined && user !== undefined) /* 有 login */ {
      const userObj = JSON.parse(user);
      // eslint-disable-next-line max-len
      const ssoUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId + '&redirectUri=' + window.location.origin;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      sessionStorage.setItem('tenant_id', userObj.tenantId);
      sessionStorage.setItem('sso_url', ssoUrl);
      initAxiosInstance();
      getAppPermission(token).then((res) => {
        /*
        **  2020-11-17 newPermission
        *   {
        *     access: true,
        *     permission: {
        *                  admin: true,
        *                  worker: true,
        *                 }
        *    }
        ** */
        if (!res.access) {
          alert('你沒有進入 Program 頁面的權限！[access]');
          const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId;
          window.location.replace(loginUrl);
        }
      }).catch((err) => {
        alert('你沒有進入 Program 頁面的權限！');
        const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId;
        window.location.replace(loginUrl);
      });
    } else /* 沒 login */ {
      // 轉傳到 login 頁面
      const params = queryString.parse(window.location.search);
      const redirectUrl = window.location.origin;
      const tenantId = sessionStorage.getItem('tenant_id') || params.tenantId || '';
      const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + tenantId + '&redirectUri=' + redirectUrl;
      window.location.replace(loginUrl);
      return null;
    }
  }

  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
