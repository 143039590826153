/* eslint-disable new-cap */
import React, {
    useRef,
} from 'react';
import Cookies from 'js-cookie';
import {
    Spin,
} from 'antd';

// import ReactHtmlParser from 'react-html-parser';


//
// IMPORT ZONE
//

function AppPopover() {
    const appSwitcher = useRef(null);
    return (
        <>
            <Spin
                style={{ position: 'relative', left: '50%', top: '50%', transform: 'translatex(-50%)' }}
                size="larges"
                id="iframeSpinner" />
            <iframe
                title="app-switcher"
                frameBorder="0"
                ref={appSwitcher}
                style={{ minWidth: '328px', minHeight: '488px', border: 'none' }}
                src={process.env.REACT_APP_SWITCHER_URL}
                onLoad={() => {
                    appSwitcher.current.contentWindow.postMessage(Cookies.get('token'), '*');
                    document.getElementById('iframeSpinner').style.display = 'none';
                }}
            />
        </>
    );
}

export default AppPopover;
