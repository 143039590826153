import React, { useState } from 'react';
import {
    Layout,
    Row,
    Col,
    Menu,
    Popover,
    Modal,
    Breadcrumb,
    Button
} from 'antd';
import {
    LogoutOutlined, DashboardOutlined, PieChartOutlined
} from '@ant-design/icons';

import './layout.css';
import GroupbyDevice from "../components/programList/GroupDeviceList";
import GroupbyOPCode from "../components/programList/GroupProductList";
import ProgramSet from "../components/programSet/ProgramSet";
import Home from '../components/home/Home';
// import Order from '../components/order/Order';
import { Route, NavLink, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import PM from '../assets/img/PROGRAM.png';
import AppPopover from './appPopover/AppPopover';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PicRightOutlined,
    EditOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
// import { DZRelease } from '@dotzerotech/release-frontend';

const { Header, Sider, Content, Footer } = Layout;
const { confirm } = Modal;



function LayoutSide() {
    const history = useHistory();
    const [switcherZIndex, setSwitcherZIndex] = useState(-1);
    const [collapsed, setCollapsed] = useState(true);
    const [breadcrumbTitle, setBreadcrumbTitle] = useState('home');
    const version = process.env.REACT_APP_PM_VERSION;

    function changeTitle(data) {
        setBreadcrumbTitle(data.key)
    }
    function changeHomeTitle(data) {
        setBreadcrumbTitle(data)
        history.push('/')
    }

    function toggle() {
        setCollapsed(!collapsed);
    };

    const onSignOut = () => {
        const tenantId = sessionStorage.getItem('tenant_id') || '';
        const logoutUrl = process.env.REACT_APP_LOGIN_URL +
            '/logout?tenantId=' + tenantId + '&redirectUri=' + window.location.origin;
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(logoutUrl);
    };


    const showConfirm = () => {
        confirm({
            title: '確定登出？',
            icon: <LogoutOutlined />,
            okText: '確認',
            cancelText: '取消',
            onOk() {
                onSignOut();
            },
            onCancel() {
            },
        });
    };

    return (
        <Layout
            style={{ overflow: 'hidden', height: '100vh', left: 0, }}
        >
            <Sider
                trigger={null} collapsible collapsed={collapsed}
                theme="dark"
                breakpoint="lg"
                collapsedWidth={window.innerWidth > 1100 ? 80 : 0}
            // style={{ overflow: 'auto', height: '100vh', left: 0, }}
            >
                <span style={{ cursor: 'pointer' }} onClick={() => changeHomeTitle("home")}>
                    {
                        collapsed ?
                            <Row style={{ marginTop: '10px', textAlign: 'center', display: collapsed ? '' : 'none' }} >
                                <Col span={24}>
                                    <div>
                                        <img width="40" height="40" alt="" src={PM} />
                                    </div>
                                </Col>
                            </Row> :
                            <Row gutter={[0, 0]} style={{ marginTop: '10px', display: collapsed ? 'none' : '' }}>
                                <Col span={9}>
                                    <div style={{ textAlign: 'center' }}>
                                        <img width="40" height="40" alt="" src={PM} />
                                    </div>
                                </Col>
                                <Col span={15}
                                    style={{
                                        color: 'white',
                                        width: 'calc(100% - 20px)',
                                        fontWeight: 'normal',
                                    }}>
                                    <div style={{ textAlign: 'left', fontSize: '16px' }}>程式管理系統</div>
                                    <div style={{ textAlign: 'left', fontSize: '10px' }}>Program Management</div>
                                </Col>
                            </Row>
                    }
                </span>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '92vh' }}>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                        <SubMenu icon={<PicRightOutlined />} key="list" title="程式列表">
                            <Menu.Item key="programDevice" icon={<DashboardOutlined />} onClick={changeTitle}>
                                <NavLink to="/programDevice" >
                                    機台/程式名稱
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="programProduct" icon={<PieChartOutlined />} onClick={changeTitle}>
                                <NavLink to="/programProduct" >
                                    OP code/物料名稱/程式名稱
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="set" icon={<EditOutlined />} onClick={changeTitle} >
                            <NavLink to="/set">
                                設定程式
                            </NavLink>
                        </Menu.Item>
                    </Menu>
                    {/* ================= logout =================== */}
                    <Menu
                        mode="inline"
                        theme="dark"
                        defaultSelectedKeys={['/']}
                        selectedKeys={[window.location.pathname]}
                        style={{ marginTop: '75vh' }}
                    >
                        <Menu.Item key="/logout" onClick={showConfirm}>
                            <LogoutOutlined />
                            <span> 登出</span>
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background"
                    style={{
                        backgroundColor: '#fff',
                        padding: 0,
                        position: 'fixed',
                        zIndex: 999,
                        width: '100%',
                        borderBottom: 'solid 1px #F0F2F4',
                    }}>
                    <Row>
                        <Col>
                            {
                                collapsed ?
                                    <MenuUnfoldOutlined className='trigger' onClick={toggle}></MenuUnfoldOutlined> :
                                    <MenuFoldOutlined className='trigger' onClick={toggle}></MenuFoldOutlined>
                            }
                        </Col>
                        <Col>
                            <Breadcrumb className='trigger'>
                                <Breadcrumb.Item><a href={process.env.REACT_APP_PM_URL}>首頁</a></Breadcrumb.Item>
                                {
                                    breadcrumbTitle === "home" ?
                                        <>
                                            {/* <a href={process.env.REACT_APP_PM_URL}>首頁</a> */}
                                        </> :
                                        breadcrumbTitle === "set" ?
                                            <Breadcrumb.Item>
                                                <a href={process.env.REACT_APP_PM_SET_URL}>設定程式</a>
                                            </Breadcrumb.Item>
                                            :
                                            breadcrumbTitle === "programDevice" ?
                                                <Breadcrumb.Item>
                                                    <a href={process.env.REACT_APP_PM_DEVICE_URL}>程式列表 (機台/程式名稱)</a>
                                                </Breadcrumb.Item> :
                                                <Breadcrumb.Item>
                                                    <a href={process.env.REACT_APP_PM_OPCODE_URL}>程式列表 (OP code/物料名稱/程式名稱)</a>
                                                </Breadcrumb.Item>
                                }
                            </Breadcrumb>
                        </Col>
                        <div style={{ position: 'fixed', top: 0, right: 50, zIndex: 10, padding: '5' }}>
                            <Row gutter={[8, 8]}>
                                <Col>
                                    {/* <DZRelease projects={[{ projectID: '29442949', projectName: 'SD' }]} /> */}
                                </Col>
                                <Col span={4} >
                                    <Popover
                                        style={{ boxShadow: '100px 10px #888888' }}
                                        trigger="click"
                                        placement="bottom"
                                        visible={true}
                                        content={
                                            <AppPopover />
                                        }
                                        zIndex={switcherZIndex}
                                    >
                                        <Button
                                            type="text"
                                            icon={<AppstoreOutlined />}
                                            onClick={() => {
                                                setSwitcherZIndex(switcherZIndex === -1 ? 999 : -1);
                                            }}
                                        />
                                    </Popover>
                                </Col>
                            </Row>
                        </div>

                    </Row>
                    {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })} */}
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        height: "100%",
                        margin: '70px 16px',
                        padding: 24,
                        minHeight: 280,
                        overflow: 'auto',

                    }}
                >
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/programDevice">
                            <GroupbyDevice />
                        </Route>
                        <Route path="/programProduct">
                            <GroupbyOPCode />
                        </Route>
                        <Route path="/set">
                            <ProgramSet />
                        </Route>
                    </Switch>
                </Content>
                <Footer
                    style={{
                        margin: '-50px 0px 0px 0px',
                        backgroundColor: '#fff',
                        textAlign: 'center',
                    }}
                >
                    DotZero {version} ©{new Date().getFullYear()}
                </Footer>

            </Layout>
        </Layout>
    );

}
export default LayoutSide;
