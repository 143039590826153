import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Radio, Tabs, Switch, Space } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from "moment";
import { GetProgramDevice } from "../../api/ProgramTable";
import GetctAvgGroupProduct from '../../api/GetctAvgGroupProduct';
import GroupDeviceChart from "../Chart/GroupDeviceChart";
import GroupProductChart from '../Chart/GroupProductChart';
import TotalCountChart from '../Chart/TotalCountChart';
const { TabPane } = Tabs;
// eslint-disable-next-line
import Chart from 'chart.js/auto'
function Home() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const today = [moment().startOf('day').format(dateFormat), moment().format(dateFormat)];
    const tomorrow = [moment().subtract(1, 'days').startOf('day').format(dateFormat), moment().subtract(1, 'days').endOf('day').format(dateFormat)];
    const oneWeekStartEndDate = [moment().subtract(7, 'd').startOf('day').format(dateFormat), moment().format(dateFormat)];
    const twoWeekStartEndDate = [moment().subtract(7, 'd').startOf('day').subtract(1, 'week').format(dateFormat), moment().format(dateFormat)];;
    const [endDate, setEndDate] = useState(today);
    const [deviceData, setDeviceData] = useState();
    const [productData, setProductData] = useState();
    const [sortDeviceAvgCT, setSortDeviceAvgCT] = useState(true);
    const [sortProductAvgCT, setSortProductAvgCT] = useState(true);
    const [sortTotalCount, setSortTotalCount] = useState(true);
    const [loadingDevice, setLoadingDevice] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(false);

    function switchSortDeviceAvgCT() {
        setSortDeviceAvgCT(preSort => !preSort);
    }
    function switchSortProductAvgCT() {
        setSortProductAvgCT(preSort => !preSort);
    }
    function switchSortTotalCount() {
        setSortTotalCount(preSort => !preSort);
    }
    async function getChartDataAPI() {
        setLoadingDevice(true)
        setLoadingProduct(true)
        await GetProgramDevice(endDate, "", "", "20").then(data => {
            setDeviceData(data)
        })
            .catch(err => console.log(err))
        setLoadingDevice(false)
        await GetctAvgGroupProduct(endDate, "20").then(data => {
            setProductData(data)
        })
            .catch(err => console.log(err))
        setLoadingProduct(false)
    }
    async function onChangeDate(e) {
        setEndDate([]);
        if (e.target.value === 'today') {
            await setEndDate(today);
        }
        if (e.target.value === 'tomorrow') {
            await setEndDate(tomorrow);
        }
        if (e.target.value === 'oneWeek') {
            await setEndDate(oneWeekStartEndDate);
        }
        if (e.target.value === 'twoWeek') {
            await setEndDate(twoWeekStartEndDate);
        }
        getChartDataAPI();
    }
    useEffect(() => {
        getChartDataAPI();
        // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Row>
                <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                </Col>
                <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    <Radio.Group
                        centered
                        optionType="button"
                        buttonStyle="solid"
                        onChange={onChangeDate}
                        defaultValue="today">
                        <Radio.Button value="today">今天</Radio.Button>
                        <Radio.Button value="tomorrow">昨天</Radio.Button>
                        <Radio.Button value="oneWeek">近一週</Radio.Button>
                        <Radio.Button value="twoWeek">近二週</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <br />
            <Divider orientation="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>程式列表統計圖(機台 / 程式名稱)</Divider>
            <div style={{ textAlign: "right", fontSize: '16px' }}>
                <Space>
                    平均CT由大到小排序
                    <Switch
                        onChange={switchSortDeviceAvgCT}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </Space>
            </div>
            <Tabs type='card' style={{ fontWeight: 'bold' }}>
                <TabPane tab="機台 / 程式名稱" key="all">
                    <GroupDeviceChart chartData={deviceData} chartLabel='all' sort={sortDeviceAvgCT} loading={loadingDevice} />
                </TabPane>
                <TabPane tab="機台" key="deviceName">
                    <GroupDeviceChart chartData={deviceData} chartLabel='deviceName' sort={sortDeviceAvgCT} loading={loadingDevice} />
                </TabPane>
                <TabPane tab="程式名稱" key="programNo">
                    <GroupDeviceChart chartData={deviceData} chartLabel='programNo' sort={sortDeviceAvgCT} loading={loadingDevice} />
                </TabPane>
            </Tabs>
            <br />
            <Divider orientation="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>程式列表統計圖(物料號碼 / OP Code / 程式名稱)</Divider>
            <div style={{ textAlign: "right", fontSize: '16px' }}>
                <Space>
                    平均CT由大到小排序
                    <Switch
                        onChange={switchSortProductAvgCT}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </Space>
            </div>
            <Tabs type='card'>
                <TabPane tab="物料號碼 / OP Code / 程式名稱" key="all">
                    <GroupProductChart chartData={productData} chartLabel='all' sort={sortProductAvgCT} loading={loadingProduct} />
                </TabPane>
                <TabPane tab="物料號碼 / 程式名稱" key="productNumberProgramNo">
                    <GroupProductChart chartData={productData} chartLabel='productNumberProgramNo' sort={sortProductAvgCT} loading={loadingProduct} />
                </TabPane>
                <TabPane tab="物料號碼" key="productNumber">
                    <GroupProductChart chartData={productData} chartLabel='productNumber' sort={sortProductAvgCT} loading={loadingProduct} />
                </TabPane>
                <TabPane tab="程式名稱" key="programNo">
                    <GroupProductChart chartData={productData} chartLabel='programNo' sort={sortProductAvgCT} loading={loadingProduct} />
                </TabPane>
            </Tabs>
            <br />
            <Divider orientation="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>程式加工次數統計圖</Divider>
            <div style={{ textAlign: "right", fontSize: '16px' }}>
                <Space>
                    加工次數由大到小排序
                    <Switch
                        onChange={switchSortTotalCount}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </Space>
            </div>
            <TotalCountChart chartData={deviceData} sort={sortTotalCount} loading={loadingDevice} />
        </React.Fragment >
    )
};
export default Home;
