import {
    Table, Space, Spin, Row, Col, Input, Button, DatePicker
} from 'antd';
import moment from "moment";
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { GetProgramDevice } from "../../api/ProgramTable";
import ProgramModal from "./ProductModal";
import WorkerModal from "./WorkerModal";
const { RangePicker } = DatePicker;
const ProgramDevice = () => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const [endDate, setEndDate] = useState([moment().startOf('day').format(dateFormat), moment().format(dateFormat)]);
    const [loading, setLoading] = useState(false);
    const [programData, setProgramData] = useState([]);
    const [programNo, setProgramNo] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const columns = [
        {
            title: '程式名稱',
            dataIndex: 'ProgramNo',
            key: 'ProgramNo',
        },
        {
            title: '機台',
            dataIndex: 'DeviceName',
            key: 'DeviceName',
        },
        {
            title: '平均CT',
            dataIndex: 'CtAvg',
            key: 'CtAvg',
            sorter: (a, b) => a.CtAvg - b.CtAvg,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最大CT',
            dataIndex: 'CtMax',
            key: 'CtMax',
            sorter: (a, b) => a.CtMax - b.CtMax,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '最小CT',
            dataIndex: 'CtMin',
            key: 'CtMin',
            sorter: (a, b) => a.CtMin - b.CtMin,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '加工次數',
            dataIndex: 'TotalCount',
            key: 'TotalCount',
            sorter: (a, b) => a.CtMin - b.CtMin,
            render: (record) => (
                <>
                    {record.toFixed(2)}
                </>
            )
        },
        {
            title: '更新時間',
            dataIndex: 'UpdateTime',
            key: 'UpdateTime',
            render: (record) => (
                <>
                    {moment(record).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '查看',
            key: 'edit',
            render: (record) =>
            (
                <Space>
                    <ProgramModal record={record} date={endDate} />
                    <WorkerModal record={record} date={endDate} />
                </Space>
            )
        },
    ];


    async function onChangeProgramNo(e) {
        setProgramNo(e.target.value)
    }

    async function onChangeDeviceName(e) {
        setDeviceName(e.target.value)
    }
    async function onChangeDate(value, dateString) {
        setEndDate(dateString);
    }
    async function searchProgram() {
        setLoading(true);
        setProgramData("");
        await GetProgramDevice(endDate, programNo, deviceName, "").then(data => {
            setProgramData(data);
        })
            .catch(err => console.log(err))
        setLoading(false);
    }

    useEffect(() => {
        searchProgram();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col span={3} >
                            <h1>更新時間 : </h1>
                        </Col>
                        <Col span={5} >
                            <RangePicker showTime
                                onChange={onChangeDate}
                                style={{ position: 'relative', textAlign: 'center', right: '5vh' }}
                                defaultValue={[moment().startOf('day'), moment()]}
                                format={dateFormat}
                            />
                        </Col>
                        <Col span={2} >
                            <h1>程式名稱 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeProgramNo} allowClear placeholder="程式名稱" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={2} >
                            <h1>機台 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeDeviceName} allowClear placeholder="機台" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={3} >
                            <Button size="middle" type="primary" onClick={searchProgram}>搜尋</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <Spin spinning={loading}>
                <Table
                    scroll={{ y: 550 }}
                    columns={columns}
                    dataSource={programData}
                    bordered
                />
            </Spin>
        </>
    )
}
export default ProgramDevice;
