import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import _ from "lodash";
import { Bar } from 'react-chartjs-2';
// import Chart from 'chart.js/auto'
function TotalCountChart({ chartData, sort, loading }) {
    const [deviceData, setDeviceData] = useState([]);
    const [labelName, setLabelName] = useState([]);
    const [CtAvg, setCtAvg] = useState([]);

    async function chartLoad(data) {
        setDeviceData("");
        if (sort) {
            data = _.orderBy(data, ['TotalCount', 'DeviceName'], ['desc', 'asc'])
        } else {
            data = _.orderBy(data, ['TotalCount', 'DeviceName'], ['asc', 'asc'])
        }
        setDeviceData(deviceData);
        setLabelName(data.map(val => val.ProgramNo))
        setCtAvg(data.map(val => val.TotalCount))
    }
    useEffect(() => {
        chartLoad(chartData);
        // eslint-disable-next-line
    }, [chartData, sort])
    const data = {
        labels: labelName,
        datasets: [{
            label: '加工次數',
            data: CtAvg,
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            borderWidth: 1
        }]
    };
    return (
        <div>
            <Spin spinning={loading}>
                <Bar
                    height="80%"
                    data={data}
                    options={{
                        title: {
                            display: true,
                            text: 'Average Rainfall per month',
                            fontSize: 20
                        },
                        legend: {
                            display: true,
                            position: 'right'
                        }
                    }}
                />
            </Spin>
        </div>
    )

};
export default TotalCountChart;
